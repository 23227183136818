import { RgbStringColorPicker } from 'react-colorful';

function ColorPicker(props) {
  return (
    <div>
      <RgbStringColorPicker color={props.color} onChange={props.setColor} />
    </div>
  );
}

export default ColorPicker;
