import React from 'react';
import { animated, useSpring } from 'react-spring';
import migoShop from './assets/migoShop.webp';
import migoShopHome from './assets/migoShopHome.webp';
import migoShopPlan from './assets/migoShopPlan.webp';
import migoShopReviews from './assets/migoShopReviews.webp';
import migoShopDark from './assets/migoShopDark.webp';
import LeftBar from './LeftBar';

function MigoShop(props) {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  function handleScroll(id) {
    const target = document.getElementById(id);
    target.scrollIntoView({ behavior: 'smooth' });
  }

  const sections = [
    { name: 'Introduction', id: 'intro' },
    { name: 'Planning', id: 'planning' },
    { name: 'Result', id: 'result' },
    { name: 'Reflection', id: 'reflection' },
  ];

  return (
    <animated.div style={fade} className='flex grow'>
      <LeftBar
        color={props.color}
        handleScroll={handleScroll}
        sections={sections}
        github='https://github.com/HRLAX48/FEC-HRLAX48'
        role='Lead Developer'
        time='4 Days'
        date='January 2022'
      />
      <div className='h-screen p-16 pb-32 overflow-y-scroll scrollBar text-lg'>
        <div
          className='flex md:hidden pb-4 font-bold gap-4'
          style={{ color: props.color }}
        >
          <a
            href='https://github.com/HRLAX48/FEC-HRLAX48'
            target='_blank'
            rel='noreferrer'
          >
            GitHub Repo
          </a>
          {/* //
          <a
            href='https://github.com/BlueOceanHRLAX48/AdjacentDoor'
            target='_blank'
            rel='noreferrer'
          >
            Demo
          </a> */}
        </div>
        <div className='text-4xl font-semibold'>migoShop</div>
        <div
          className='w-full h-px -mt-1 -z-10'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <img src={migoShopHome} alt='MainDemo' className='rounded mt-4' />
        <div id='intro' className='mt-8 text-4xl font-semibold'>
          Introduction
        </div>
        <p className='pt-4'>
          For this project I collaborated closely with 2 other engineers to
          design and build a front end utilizing data from a provided API. The
          site was broken down into 4 main components: Product Overview, Related
          Items, Questions & Answers, and Ratings & Reviews. We had 3 engineers
          instead of 4 so I ended up taking on both the Q&A and Reviews
          components. We also went a bit overboard and built out the catalogue
          and cart functionality despite the API not exactly being built for it.
        </p>
        <p className='pt-4'>
          This was my first real project working with other team members and was
          a constant learning experience. It taught me a lot about the Git
          workflow, utilizing multiple feature branches, the importance of
          consistent and clear communication of ideas, and more. It was a good
          way to get a taste of working on a team without being thrown fully
          into it just yet since we still had separate component assignments
          that ended up functioning independently of others. Well, we ended up
          connecting them for some extra functionality but they were separate
          enough.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='planning' className='text-4xl font-semibold'>
          Planning
        </div>
        <p className='py-4'>
          The scope of this project was restricted to just the front end so our
          planning began with honing in on a design.
        </p>
        <img src={migoShopPlan} alt='plan' className='rounded-lg my-4' />
        <p className='py-4'>
          Once we had the overall design layout in mind, we started to go
          through the documentation for the API. It was clearly designed with a
          single product detail page in mind and with that came limitations that
          we knew we would end up having to work around if we wanted to do
          additional things later. We also decided to utilize Bootstrap for this
          build, so there was a bit of adjusting to get used to the new
          classnames and methods of styling things.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='result' className='text-4xl font-semibold'>
          Result
        </div>
        <p className='py-4'>
          The project was supposed to just be a single product detail page, but
          we wanted to do more. We ended up implementing a catalogue (which was
          the first real hurdle working with the API because the product list
          endpoint didn't return any image data) so I had to improvise and get a
          little <i>hacky</i>. For every item that came back in the catalogue
          query, I then had to query a different endpoint for its product image
          before I could load it to the page. This resulted in a lot of extra
          queries to the database that also happened to have a relatively low
          rate limit... So that was a problem. I came up with another workaround
          for that, though. Certainly not ideal, but our authentication was via
          GitHub token so I decided to generate 10 tokens and have the site
          select 1 at random from the array of 10 whenever you'd load the page.
        </p>
        <img src={migoShop} alt='catalogue' className='rounded-lg my-4' />
        <img src={migoShopHome} alt='catalogue' className='rounded-lg my-4' />
        <img
          src={migoShopReviews}
          alt='catalogue'
          className='rounded-lg my-4'
        />
        <p className='py-4'>
          And as with most things I build, I made sure to implement a dark mode.
          This was actually the first project I ever attempted it with and it
          turned out to be a lot simpler than I thought.
        </p>
        <img src={migoShopDark} alt='catalogue' className='rounded-lg my-4' />
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='reflection' className='text-4xl font-semibold'>
          Reflection
        </div>
        <p className='py-4'>
          The biggest takeaway for me on this project was the experience of
          working with a team. Prior to migoShop, I had only ever done pair
          programming or solo work and Git conflicts were nonexistent. I was
          also the most experienced with React of the 3 of us so it was great
          experience getting to explain various concepts about the framework.
        </p>
        <p>
          As for things I'd have changed... I know it's a popular framework but
          I really didn't enjoy working with Bootstrap. It felt unnecessarily
          restrictive to me but I think I have to attribute that to my lack of
          knowledge with it more than the framework itself. I'm a huge Tailwind
          fan and would have loved to have used it on this project instead of
          Bootstrap but was pleased regardless with the outcome of the project.
        </p>
      </div>
    </animated.div>
  );
}

export default MigoShop;
