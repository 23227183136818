import React from 'react';
import { animated, useSpring } from 'react-spring';
import Spotify from './assets/spotify.png';
import SpotifyVideo from './assets/SpotifyVideo.mov';
import LeftBar from './LeftBar';

function ReactSpotify(props) {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  function handleScroll(id) {
    const target = document.getElementById(id);
    target.scrollIntoView({ behavior: 'smooth' });
  }

  const sections = [
    { name: 'Introduction', id: 'intro' },
    { name: 'Planning', id: 'planning' },
    { name: 'Result', id: 'result' },
    { name: 'Reflection', id: 'reflection' },
  ];

  return (
    <animated.div style={fade} className='flex grow'>
      <LeftBar
        color={props.color}
        handleScroll={handleScroll}
        sections={sections}
        github='https://github.com/milesmaroney/SpotifyV2'
        role='Solo'
        time='4 Hours'
        date='December 2021'
      />
      <div className='h-screen p-16 pb-32 overflow-y-scroll scrollBar text-lg'>
        <div
          className='flex md:hidden pb-4 font-bold gap-4'
          style={{ color: props.color }}
        >
          <a
            href='https://github.com/milesmaroney/SpotifyV2'
            target='_blank'
            rel='noreferrer'
          >
            GitHub Repo
          </a>
          {/* //
          <a
            href='https://github.com/BlueOceanHRLAX48/AdjacentDoor'
            target='_blank'
            rel='noreferrer'
          >
            Demo
          </a> */}
        </div>
        <div className='text-4xl font-semibold'>reactSpotify</div>
        <div
          className='w-full h-px -mt-1 -z-10'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <img src={Spotify} alt='MainDemo' className='rounded mt-4' />
        <div id='intro' className='mt-8 text-4xl font-semibold'>
          Introduction
        </div>
        <p className='pt-4'>
          One of my earliest projects, reactSpotify was practice to start
          learning NextJS, NextAuth, Recoil, and Tailwind.
        </p>
        <p className='pt-4'>
          It's a simple front end that hooks into the Spotify API using their
          OAuth to login and pull your actual Spotify data to the webapp.
          Unfortunately, limitations in Spotify's API mean that it's basically a
          glorified remote for the native Spotify app on your device.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='planning' className='text-4xl font-semibold'>
          Planning
        </div>
        <p className='py-4'>
          When I started on this project, I had 0 prior experience in any of the
          technologies I inteded to use aside from React, so there was plenty of
          documentation scouring early on. Once I had a vague idea of how to use
          the various tech I had selected, I started to plan out the front end
          components and how they'd come together to make up the Spotify UI.
          Being a single page app, it didn't take too long and I'd say the
          majority of time was then spent figuring out the OAuth implementation.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='result' className='text-4xl font-semibold'>
          Result
        </div>
        <video src={SpotifyVideo} autoPlay loop className='my-4 rounded-lg' />
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='reflection' className='text-4xl font-semibold'>
          Reflection
        </div>
        <p className='py-4'>
          I built this project at a point in my career when I didn't really
          understand the documentation and instructions I was following so I
          feel I didn't actually get a whole lot out of this. My biggest value
          in this project was my exposure to NextJS but I'd certainly like to
          revisit the framework to improve my understanding of it soon.
        </p>
        <p>
          I also learned the Spotify API is... annoying. Between how restrictive
          it is so that you can't actually create a standalone app and how many
          things were broken when I was building this, I'd like to not have to
          deal with their API again. I absolutely love music so I don't imagine
          this will be my last music related build... I'll just have to build my
          own API for that instead.
        </p>
      </div>
    </animated.div>
  );
}

export default ReactSpotify;
