import React from 'react';

function PortfolioApp(props) {
  const [hovered, setHovered] = React.useState(false);

  return (
    <div
      className='w-full h-96 flex flex-col text-center bg-neutral-800 border border-black rounded-md transition-all duration-300'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={hovered ? { boxShadow: `0 0 10px 0px ${props.color}` } : {}}
    >
      <div className='shadow shadow-black h-2/5'>
        <img
          src={props.img}
          className='rounded-t-md border-b h-full w-full object-cover'
          alt='Portfolio Demo'
          style={{ borderColor: props.color }}
        />
      </div>
      <div className='pt-1 text-xl'>{props.name}</div>
      <div className='-mt-0.5 text-xs tracking-tight font-semibold text-neutral-600'>
        {props.category}
      </div>
      <div
        className='px-4 pt-1 text-sm text-neutral-400 transition-colors duration-300'
        style={hovered ? { color: 'white' } : {}}
      >
        {props.description}
      </div>
    </div>
  );
}

export default PortfolioApp;
