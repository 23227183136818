import React from 'react';
import { animated, useSpring } from 'react-spring';
import systemScale from './assets/systemScale.webp';
import systemScale9K from './assets/systemScale9K.webp';
import systemScaleWhiteboard from './assets/systemScaleWhiteboard.webp';
import systemScaleQueries from './assets/systemScaleQueries.webp';
import systemScaleBaseline from './assets/systemScaleBaseline.webp';
import systemScaleProductsList from './assets/systemScaleProductsList.webp';
import systemScaleProductStyles from './assets/systemScaleProductStyles.webp';
import systemScaleRelated from './assets/systemScaleRelated.webp';
import LeftBar from './LeftBar';

function SystemScale(props) {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  function handleScroll(id) {
    const target = document.getElementById(id);
    target.scrollIntoView({ behavior: 'smooth' });
  }

  const sections = [
    { name: 'Introduction', id: 'intro' },
    { name: 'Planning', id: 'planning' },
    { name: 'Result', id: 'result' },
    { name: 'Reflection', id: 'reflection' },
  ];

  return (
    <animated.div style={fade} className='flex grow'>
      <LeftBar
        color={props.color}
        handleScroll={handleScroll}
        sections={sections}
        github='https://github.com/HRLAX48-SDC/OverviewAPI'
        role='Solo'
        time='1 Week'
        date='January 2022'
      />
      <div className='h-screen p-16 pb-32 overflow-y-scroll scrollBar text-lg'>
        <div
          className='flex md:hidden pb-4 font-bold gap-4'
          style={{ color: props.color }}
        >
          <a
            href='https://github.com/HRLAX48-SDC/OverviewAPI'
            target='_blank'
            rel='noreferrer'
          >
            GitHub Repo
          </a>
          {/* //
          <a
            href='https://github.com/BlueOceanHRLAX48/AdjacentDoor'
            target='_blank'
            rel='noreferrer'
          >
            Demo
          </a> */}
        </div>
        <div className='text-4xl font-semibold'>systemScale</div>
        <div
          className='w-full h-px -mt-1 -z-10'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <img src={systemScale9K} alt='MainDemo' className='rounded mt-4' />
        <div id='intro' className='mt-8 text-4xl font-semibold'>
          Introduction
        </div>
        <p className='pt-4'>
          systemScale was an exercise in gaining exposure to the world of
          scaling an application for high traffic. We were given an assortment
          of .CSV files, each with over 5 million records, and told to return
          the expected data in a designated shape in an efficient manner. Prior
          to this, all of my work had been targeted at small audiences in the
          form of demos and private projects so this was quite a jarring task
          for me.
        </p>
        <p className='pt-4'>
          Given its prevalence in the industry, the one piece of the puzzle that
          I knew I wanted to utilize was PostgreSQL so that I could not only
          familiarize myself with it, but also dive a bit deeper and start to
          learn the intricacies of allowing it to scale. With no further
          instruction, it was time to begin researching.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='planning' className='text-4xl font-semibold'>
          Planning
        </div>
        <p className='py-4'>
          My first step was understand what data was being requested, what shape
          it was expected in, and what kind of relationships I was going to have
          to establish between tables to make that happen.
        </p>
        <div className='flex flex-col md:flex-row w-full items-center'>
          <a
            href='https://miro.com/app/board/uXjVOWG1z4k=/?invite_link_id=799234441951'
            className='md:w-1/2'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={systemScaleWhiteboard}
              alt='whiteboarding'
              className='rounded-lg'
            />
          </a>
          <p className='md:w-1/2 pl-4'>
            I started off whiteboarding out a rough plan for the table
            relationships in a relational database structure as well as one for
            a NoSQL solution. Ultimately I decided to continue with PostgreSQL
            because I had no experience with it while I have a very solid
            understanding of MongoDB. For a direct link to the board, click{' '}
            <a href='https://miro.com/app/board/uXjVOWG1z4k=/?invite_link_id=799234441951'>
              <b>here.</b>
            </a>
          </p>
        </div>
        <img
          src={systemScaleQueries}
          alt='queries'
          className='rounded-lg my-8'
        />
        <p>
          The first step was to build out all of the queries I was going to
          need, which ultimately led to my discovery of PostgreSQL's aggregation
          methods. These were key to returning the data in the required format.
          I toyed with the idea of offloading some of the work from the database
          and doing some of the data shaping on the server but ultimately went
          back to doing everything in the query as the t2.micro seemed to become
          a bottleneck with more demand on it. The server itself was set up
          fairly quickly as I only needed to build in 4 routes for it.
        </p>
        <p className='pt-4'>
          While everything was still on my local machine, I was testing each
          endpoint with K6 to document my performance with each change.
          Eventually, I was able to sustain my desired throughput across all 4
          endpoints.
        </p>
        <img src={systemScale} alt='k6testing' className='rounded-lg my-8' />
        <p className='pt-4'>
          From there, it was time to deploy. I knew I wanted to maintain
          separation of concerns as much as possible so my original deployment
          plan was to separate the database from the server on different
          instances. I wanted to do this 1:1 pair to get a baseline idea of
          performance before I attempted to optimize further.
        </p>
        <p className='pt-4'>
          My baseline testing at 1,000 requests per second was pretty rough. I
          was able to respond to all of the requests without any errors but my
          system was falling behind to an average response time of 2,000ms by
          the end of the tests.
        </p>
        <img
          src={systemScaleBaseline}
          alt='baseline'
          className='rounded-lg my-8'
        />
        <p className='pt-4'>
          Fast-forwarding a bit, it's time to make some optimizations. My next
          step was to implement a load balancer and a 2nd server instance.
          Initial improvements were very promising... I put the 2 servers behind
          an NGINX load balancer and saw the average response time fall from
          2,000ms to just <b>84ms</b>. I attempted to utilize both a round robin
          as well as least_connections load balancing scheme and found no
          meaningful difference between the two. Ultimately I ended up sticking
          with the least_connections strategy because while I saw no tangible
          difference in the data, logically I imagined that it would be unlikely
          that every query will return in the same amount of time, eventually
          leading to imbalance as the number of requests grew if I was using the
          round robin strategy.
        </p>
        <p className='pt-4'>
          Next step was to implement caching in the load balancer. After some
          fine tuning and manipulating the different options available in NGINX,
          I was able to reach 8,000 requests per second on 2 of my 4 endpoints
          with an average response time under 70ms. Increasing the number of
          active worker connections allowed me to push even further to serve
          9,000 requests per second with no increase in average response time.
        </p>
        <p className='pt-4'>
          The final optimization I tried was implementing a Redis cache at the
          server level. I had heard great things about Redis and was quite
          excited to get it implemented only to find that it made very little
          difference when paired with the NGINX caching. I continued to utilize
          it as I figured it would just increase reliability with another layer
          of caching but saw no notable performance gains in average response
          time or request throughput.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='result' className='text-4xl font-semibold'>
          Result
        </div>
        <p className='py-4'>
          Overall, the optimization in systemScale took my endpoints from
          serving 100 requests per second to over 9,000 without utilizing any
          additional budget. My final numbers for each endpoint were:
        </p>
        <div className='flex w-full pt-4 gap-4'>
          <div className='w-1/2 text-center font-bold'>
            Product List - 7,000 RPS @ 64ms
            <img
              src={systemScaleProductsList}
              alt='productlist'
              className='rounded-lg'
            />
          </div>
          <div className='w-1/2 text-center font-bold'>
            Related - 8,500 RPS @ 62ms
            <img
              src={systemScaleRelated}
              alt='related'
              className='rounded-lg'
            />
          </div>
        </div>
        <div className='flex w-full pt-4 gap-4'>
          <div className='w-1/2 text-center font-bold'>
            Product Detail - 9,000 RPS @ 64ms
            <img
              src={systemScale9K}
              alt='productdetail'
              className='rounded-lg'
            />
          </div>
          <div className='w-1/2 text-center font-bold'>
            Product Styles - 7,000 RPS @ 64ms
            <img
              src={systemScaleProductStyles}
              alt='styles'
              className='rounded-lg'
            />
          </div>
        </div>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='reflection' className='text-4xl font-semibold'>
          Reflection
        </div>
        <p className='py-4'>
          systemScale was my first real experience working solely with the
          back-end and I'll be honest... it was not my favorite. I gained a new
          respect for back end engineers and what it actually takes to scale an
          application for high traffic. While I'd feel comfortable being tasked
          with scaling again, this project helped solidify my thought process
          that I much prefer front end work.
        </p>
        <p>
          The single largest optimization I made was implementing the NGINX
          cache which took me from ~2,000 RPS to north of 8,000 in some routes.
          Without a doubt this was the MVP of systemScale for me. The next
          closest was likely the introduction of the load balancer on its own
          and splitting the requests between 2 servers. However, while the
          increase from 1 to 2 servers was relatively impactful, the increase
          from 2 to 3 and then 4 was marginal. Similarly, I saw little
          difference with my choice of least_connections instead of round robin
          for the load balancing strategy. Adding Redis caching was also a bit
          of a letdown as I saw negligible difference with and without it
          implemented on my API servers. The last quantifiable increase in
          performance was raising the number of active workers in NGINX from the
          default value of 7XX to the AWS soft limit of 1024. At the end, the
          bottleneck in my final setup seemed to be the database so any further
          optimization would've taken place there but I was happy enough with my
          final results to not delve deeper and instead focus on other things.
          If I were to go back and start over again, I'd be curious to build the
          backend from the ground up again using a NoSQL database to see how
          things would compare.
        </p>
      </div>
    </animated.div>
  );
}

export default SystemScale;
