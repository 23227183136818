import PortfolioApp from './PortfolioApp';
import AdjacentDoorHome from './assets/AdjacentDoorHome.webp';
import mapper from './assets/mapper.webp';
import workoutKeeper from './assets/workoutKeeper.webp';
import systemScale from './assets/systemScale.webp';
import mChat from './assets/mchat.png';
import migoShop from './assets/migoShopHome.webp';
import Spotify from './assets/spotify.png';
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import React from 'react';

export default function Engineer(props) {
  const [hover, setHover] = React.useState(false);

  const apps = [
    {
      name: 'AdjacentDoor',
      image: AdjacentDoorHome,
      category: 'FULL STACK',
      description:
        'Over the course of a week, I led a team of 7 engineers as Product Manager and Lead Developer to deliver a full stack application allowing neighbors to socialize, share safety information, buy and sell items, and more',
    },
    {
      name: 'systemScale',
      image: systemScale,
      category: 'BACK END',
      description:
        'A system architecture built to handle high sustained and spiking user traffic through horizontal scaling. Achieved sustained 7,000RPS+ with a sub 0.1% error rate at less than 70ms latency across all endpoints with a database of over 5 million records. Best endpoint reached 9,000RPS @ 62ms with 0.1% error rate',
    },
    {
      name: 'mapper',
      image: mapper,
      category: 'FRONT END',
      description:
        'Built over 48 hours under a Minimum Viable Product pretense, mapper is a rideshare and food delivery mobile application front end built in React Native utilizing multiple Google APIs',
    },
    {
      name: 'workoutKeeper',
      image: workoutKeeper,
      category: 'FULL STACK',
      description:
        'Built as a personal passion project, workoutKeeper is full stack application built to store and share your favorite workouts. I personally use it every day and will eventually revisit it to expand the feature set',
    },
    {
      name: 'migoShop',
      image: migoShop,
      category: 'FRONT END',
      description:
        'This was a group project for HackReactor where we were tasked with buildling a product detail page to dynamically render the data provided to us based on client requests. We ultimately decided to go beyond the minimum requirements and implement dark mode and the cart/checkout process',
    },
    {
      name: 'mChat',
      image: mChat,
      category: 'FULL STACK',
      description:
        'A Twitch chat clone with authentication and persistent user preferences. I originally set out to experiment with conditional inline styling and expanded the feature set to what you see in the demo. The auto scroll / pausing was particularly enjoyable to implement. Built with React, Tailwind, MongoDB, and bCrypt',
    },
    {
      name: 'reactSpotify',
      image: Spotify,
      category: 'FRONT END',
      description:
        'A Spotify web app clone fully hooked into the Spotify API to control your music. Built with NextJS, NextAuth, Tailwind, and Recoil',
    },
  ];

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const Applications = apps.map((app, i) => (
    <div className='w-80' key={i}>
      <Link to={`/${app.name}`}>
        <PortfolioApp
          color={props.color}
          name={app.name}
          img={app.image}
          category={app.category}
          description={app.description}
        />
      </Link>
    </div>
  ));
  return (
    <animated.div className='w-screen justify-center text-center overflow-y-scroll sm:pt-8' style={fade}>
      <div className='text-xl md:text-4xl pt-8 sm:pt-0 font-semibold'>Full Stack Software Engineer</div>
      <div className='text-sm md:text-base px-8 pt-4'>
        Graduate of{' '}
        <a
          href='https://www.hackreactor.com/coding-bootcamp'
          target='_blank'
          rel='noreferrer'
          className='font-semibold transition-all duration-300'
          style={{ color: hover && props.color }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          HackReactor's Software Engineering Immersive
        </a>{' '}
        program in February 2022.
      </div>
      <div className='text-sm md:text-base pt-2 px-6'>
        Went from never having written a line of code to Software Engineer at Amazon in 6 months.
      </div>
      <div className='text-lg md:text-2xl mt-12 font-semibold border-b' style={{ borderColor: props.color }}>
        Personal Projects
      </div>
      <div className='flex flex-col py-12 md:px-8 gap-8 justify-center items-center sm:flex-row flex-wrap'>
        {Applications}
      </div>
    </animated.div>
  );
}
