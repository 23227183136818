import { FaGithub, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';

export default function Contact(props) {
  return (
    <div className='flex flex-col gap-8 justify-center items-center grow text-xl relative'>
      <div
        className='h-full w-full absolute animate-pulse -z-10'
        style={{ boxShadow: `inset 0 -100px 150px -150px ${props.color}` }}
      ></div>
      <a
        href='http://instagram.com/milesmaroney'
        rel='noreferrer'
        target='_blank'
      >
        <div className='flex items-center'>
          <div className='pr-4'>
            <FaInstagram size='40px' />
          </div>
          <div>@milesmaroney</div>
        </div>
      </a>
      <a
        href='http://linkedin.com/in/milesmaroney'
        rel='noreferrer'
        target='_blank'
      >
        <div className='flex items-center'>
          <div className='pr-4'>
            <FaLinkedinIn size='40px' />
          </div>
          <div>/in/milesmaroney</div>
        </div>
      </a>
      <a href='http://github.com/milesmaroney' rel='noreferrer' target='_blank'>
        <div className='flex items-center'>
          <div className='pr-4'>
            <FaGithub size='40px' />
          </div>
          <div>@milesmaroney</div>
        </div>
      </a>
      <a href='mailto:miles@milesmaroney.com' rel='noreferrer' target='_blank'>
        <div className='flex items-center'>
          <div className='pr-4'>
            <HiOutlineMail size='40px' />
          </div>
          <div>miles@milesmaroney.com</div>
        </div>
      </a>
    </div>
  );
}
