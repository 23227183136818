import { animated, useSpring } from 'react-spring';
import GT3Fade from './assets/GT3Fade.webp';
import Kart from './assets/kart.webp';
import Helmet from './assets/Helmet.webp';

export default function Racer(props) {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  return (
    <animated.div style={fade} className='flex grow overflow-hidden relative'>
      <div className='w-full lg:w-2/5 flex flex-col items-start p-8 lg:p-20 gap-12'>
        <img src={Kart} alt='Karting' className='w-full rounded-lg' />
        <div className='text-sm md:text-base'>
          Miles racing journey began at the age of 4 when he was introduced to a
          go-kart on a damp morning at Adam's Kart Track in Riverside, CA. From
          that first moment on, the dominos continued to fall and Miles would go
          on to claim multiple national championships through numerous series as
          he climbed the open wheel and sportscar ladder system. Today, Miles is
          a gun for hire talent in the racing world and a renowned private
          driving coach and instructor for prestigious automotive brands such as
          McLaren, Lamborghini, and Bentley.
        </div>
      </div>
      <img
        src={GT3Fade}
        alt='GT3'
        className='absolute bottom-0 -z-20 lg:hidden'
      />
      <img
        src={Helmet}
        alt='Helmet'
        className='absolute h-full bottom-0 right-0 -z-10 opacity-0 lg:opacity-60 transition-opacity duration-300'
      />
    </animated.div>
  );
}
