import { Link } from 'react-router-dom';
import Icon from './assets/IconWhiteTrimmed.png';
import { animated, useSpring } from 'react-spring';
import React from 'react';

export default function Home(props) {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  const halfColor =
    'rgb(' +
    props.color
      .slice(4, props.color.length - 1)
      .split(',')
      .map(Number)
      .map((x) => Math.floor(x / 2))
      .join(',') +
    ')';

  return (
    <div
      className='flex justify-center items-center h-full'
      style={{
        background: `linear-gradient(180deg, rgb(30,30,30) 0%, ${halfColor} 100%)`,
      }}
    >
      <animated.div
        className='flex flex-col gap-16 md:flex-row justify-center items-center w-full'
        style={fade}
      >
        <Link to='/engineer' className='hidden md:flex'>
          <button className='btn'>SOFTWARE ENGINEER</button>
        </Link>
        <div className='flex flex-col justify-center items-center md:w-1/2'>
          <img src={Icon} alt='Main Logo' className='w-2/5 md:w-1/4' />
          <div
            className='w-3/4 md:w-1/2 h-px mt-3 mb-1.5 shadow-black shadow'
            style={{ backgroundColor: props.color }}
          ></div>
          <span className='text-slate-100 tracking-wider lg:text-lg cursor-default'>
            <a
              href='mailto:miles@milesmaroney.com'
              target='_blank'
              rel='noreferrer'
            >
              MILES MARONEY
            </a>
          </span>
        </div>
        <div className='flex mt-20 md:mt-0'>
          <Link to='/engineer' className='md:hidden'>
            <button className='btn'>SOFTWARE ENGINEER</button>
          </Link>
          <Link to='/racer'>
            <button className='btn'>RACECAR DRIVER</button>
          </Link>
        </div>
      </animated.div>
    </div>
  );
}
