import React from 'react';
import { animated, useSpring } from 'react-spring';
import mapper from './assets/mapper.webp';
import mapperSetFav from './assets/mapperSetFav.mp4';
import mapperWaypoint from './assets/mapperWaypoint.mp4';
import mapperFood from './assets/mapperFood.mp4';
import AdjacentDoorGroupAdmin from './assets/AdjacentDoorGroupAdmin.webp';
import AdjacentDoorMyProfile from './assets/AdjacentDoorMyProfile.webp';
import AdjacentDoorComments from './assets/AdjacentDoorComments.webp';
import LeftBar from './LeftBar';

function Mapper(props) {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  function handleScroll(id) {
    const target = document.getElementById(id);
    target.scrollIntoView({ behavior: 'smooth' });
  }

  const sections = [
    { name: 'Introduction', id: 'intro' },
    { name: 'Planning', id: 'planning' },
    { name: 'Result', id: 'result' },
    { name: 'Reflection', id: 'reflection' },
  ];

  return (
    <animated.div style={fade} className='flex grow'>
      <LeftBar
        color={props.color}
        handleScroll={handleScroll}
        sections={sections}
        github='https://github.com/milesmaroney/mapper'
        role='Solo'
        time='48 Hours'
        date='January 2022'
      />
      <div className='h-screen p-16 pb-32 overflow-y-scroll scrollBar text-lg'>
        <div
          className='flex md:hidden pb-4 font-bold gap-4'
          style={{ color: props.color }}
        >
          <a
            href='https://github.com/BlueOceanHRLAX48/AdjacentDoor'
            target='_blank'
            rel='noreferrer'
          >
            GitHub Repo
          </a>
          {/* //
          <a
            href='https://github.com/BlueOceanHRLAX48/AdjacentDoor'
            target='_blank'
            rel='noreferrer'
          >
            Demo
          </a> */}
        </div>
        <div className='text-4xl font-semibold'>mapper</div>
        <div
          className='w-full h-px -mt-1 -z-10'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <img src={mapper} alt='MainDemo' className='rounded mt-4' />
        <div id='intro' className='mt-8 text-4xl font-semibold'>
          Introduction
        </div>
        <p className='pt-4'>
          mapper is a ride share and food delivery mobile application front end
          built using React Native utilizing multiple Google APIs. By the time
          the MVP (Minimum Viable Product) project rolled around in HackReactor,
          I had already built the few applications I had planned on building. I
          wanted to continue my learning so I had to start thinking out of the
          box and decided that it was best that I get my feet wet with React
          Native and try to build a mobile application.
        </p>
        <p className='pt-4'>
          The premise of MVP in HackReactor is that you have a strict 48 hour
          time constraint to build whatever you want from the ground up.
          Deciding to go with a React Native build was a bit daunting as I had
          never used the technology before, but I felt there wasn't much to be
          gained with another full stack webapp project (other ideas I had and
          built were my workoutKeeper and mChat applications) so new tech it
          was. I had also only ever used Redux once before in a very limited
          capacity so I wanted to utilize it as well to get more practice.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='planning' className='text-4xl font-semibold'>
          Planning
        </div>
        <p className='py-4'>
          First things first, I had to dive into the React Native docs. I knew
          absolutely nothing going in so I needed to be a sponge. Fortunately,
          my comfort level with React allowed me to get the hang of the new
          syntax relatively quickly and after getting an understanding of some
          of the different elements I had access to, I was ready to start my
          mental modeling of how I wanted the app to flow. By now, if you've
          looked at the screenshots above, I'm sure the app feels at least
          somewhat familiar... This meant I had a little bit of help in my UI/UX
          planning and freed up more time for me to focus on actual
          implementation.
        </p>
        <p className='py-4'>
          I knew that I was going to end up needing a few APIs for my intended
          functionality so I started looking up different Google APIs to
          determine which of their services I could utilize. Once I narrowed
          things down to the Places, Directions, and DistanceMatrix APIs, it was
          time to start planning how I could functionally achieve what I wanted.
          I came across many suggestions online for a number of packages that
          ultimately proved to be super valuable and simplified my build process
          quite a bit. I discovered that I could utilize React Native Screens to
          implement the UX I was aiming for, React Native Maps for the native
          mapping component, and I even learned I can use my personal favorite
          Tailwind! Music to my ears and a ton of time saved.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='result' className='text-4xl font-semibold'>
          Result
        </div>
        <p className='py-4'>
          At the end of the 1st day, the majority of the core functionality was
          implemented. This meant that I got to spend the entire 2nd day having
          fun and mapper ended up with more features than I had originally
          planned. A personal favorite was the ability to set and clear a couple
          favorite locations as well as the ability to set waypoints and reroute
          accordingly (thanks for the suggestion Quinton 😄). The inclusion of
          light and dark mode was also a must.
        </p>
        <div className='flex w-full flex-col md:flex-row items-center md:items-start md:justify-center pt-4 gap-4'>
          <video
            src={mapperSetFav}
            alt='set favorite demo'
            className='rounded-lg w-3/4 md:w-1/4'
            autoPlay
            playsInline
            loop
          />
          <video
            src={mapperWaypoint}
            alt='set waypoint demo'
            className='rounded-lg w-3/4 md:w-1/4'
            autoPlay
            playsInline
            loop
          />
          <video
            src={mapperFood}
            alt='food demo'
            className='rounded-lg w-3/4 md:w-1/4'
            autoPlay
            playsInline
            loop
          />
        </div>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='reflection' className='text-4xl font-semibold'>
          Reflection
        </div>
        <p className='py-4'>
          React Native wasn't nearly as intimidating as I had originally
          thought. Once I got the ball rolling and understood when to use
          FlatLists, Views, and everything else unique to React Native, the app
          actually came together fairly quickly. I was impressed with the amount
          of packages available for just about everything I wanted to achieve
          with the native maps component.
        </p>
        <p>
          I still much prefer regular React, but if I had to do it again I think
          I'd enjoy getting to work with React Native more and it's always
          awesome to be able to show someone an app on their mobile device
          instead of <i>go to this website.</i> There were a number of styling
          things that I didn't love at the end that I'd have liked to have gone
          back and fixed but I introduced them late in the timeframe with new
          features and didn't have time to fix them all before having to
          present.
        </p>
      </div>
    </animated.div>
  );
}

export default Mapper;
