import Logo from './assets/IconBlackTrimmed.png';
import { isActive, NavLink, useLocation } from 'react-router-dom';
import ColorPicker from './ColorPicker';
import React from 'react';
import useClickOutside from './useClickOutside';
import { FaGithub, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { IoIosArrowDropdown } from 'react-icons/io';

function Header(props) {
  const [showColor, setShowColor] = React.useState(false);
  const picker = React.useRef();

  useClickOutside(picker, () => setShowColor(false));
  const nav = `cursor-pointer px-2 md:px-4 underline-offset-4`;
  const activeStyle = {
    color: 'white',
    textDecoration: 'underline',
    textDecorationColor: props.color,
    textDecorationThickness: '2px',
  };
  const bgStyle = {
    backgroundColor:
      useLocation().pathname === '/' ? 'transparent' : 'rgb(30, 30, 30)',
    position: useLocation().pathname === '/' ? 'absolute' : '',
  };

  return (
    <div
      className='flex w-screen items-center justify-center sm:justify-start lg:py-4 px-5 py-2 font-semibold text-neutral-300 text-xs sm:text-sm md:text-base'
      style={bgStyle}
    >
      <div
        className='relative cursor-pointer mr-2 md:mr-4 opacity-100 hover:opacity-80 transition-opacity duration-300'
        onClick={() => setShowColor((x) => !x)}
        ref={picker}
        style={{ color: props.color }}
      >
        <IoIosArrowDropdown size='20px' />
      </div>
      <div
        className='absolute top-8 sm:top-16 left-4 z-10 transition-all duration-300'
        style={{
          opacity: showColor ? '1' : '0',
          visibility: showColor ? 'visible' : 'hidden',
        }}
      >
        <ColorPicker color={props.color} setColor={props.setColor} />
      </div>
      <NavLink
        to='/'
        className={nav}
        style={({ isActive }) => (isActive ? activeStyle : {})}
      >
        <div>Home</div>
      </NavLink>
      <NavLink
        to='/about'
        className={nav}
        style={({ isActive }) => (isActive ? activeStyle : {})}
      >
        <div>About</div>
      </NavLink>
      <NavLink
        to='/engineer'
        className={nav}
        style={({ isActive }) => (isActive ? activeStyle : {})}
      >
        <div>Software Dev</div>
      </NavLink>
      <NavLink
        to='/racer'
        className={nav}
        style={({ isActive }) => (isActive ? activeStyle : {})}
      >
        <div>Racing</div>
      </NavLink>
      <NavLink
        to='/contact'
        className={`${nav} sm:hidden`}
        style={({ isActive }) => (isActive ? activeStyle : {})}
      >
        <div>Contact</div>
      </NavLink>
      <div className='ml-auto hidden sm:flex gap-4 items-center text-neutral-400'>
        <div className='hover:text-white transition-all duration-300'>
          <a
            href='http://instagram.com/milesmaroney'
            rel='noreferrer'
            target='_blank'
          >
            <FaInstagram size='25px' />
          </a>
        </div>
        <div className='hover:text-white transition-all duration-300'>
          <a
            href='http://linkedin.com/in/milesmaroney'
            rel='noreferrer'
            target='_blank'
          >
            <FaLinkedinIn size='25px' />
          </a>
        </div>
        <div className='hover:text-white transition-all duration-300'>
          <a
            href='http://github.com/milesmaroney'
            rel='noreferrer'
            target='_blank'
          >
            <FaGithub size='23px' />
          </a>
        </div>
        <div className='hover:text-white transition-all duration-300'>
          <a
            href='mailto:miles@milesmaroney.com'
            rel='noreferrer'
            target='_blank'
          >
            <HiOutlineMail size='30px' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
