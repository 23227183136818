import React from 'react';
import { animated, useSpring } from 'react-spring';
import mChat from './assets/mChat.webp';
import mChatLight from './assets/mChatLight.webp';
import mChatVideo from './assets/mChatVideo.mov';
import AdjacentDoorWhiteboard from './assets/AdjacentDoorWhiteboard.webp';
import AdjacentDoorFigma from './assets/AdjacentDoorFigma.webp';
import AdjacentDoorGroup from './assets/AdjacentDoorGroup.webp';
import AdjacentDoorGroupAdmin from './assets/AdjacentDoorGroupAdmin.webp';
import AdjacentDoorMyProfile from './assets/AdjacentDoorMyProfile.webp';
import AdjacentDoorComments from './assets/AdjacentDoorComments.webp';
import LeftBar from './LeftBar';

function MChat(props) {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  function handleScroll(id) {
    const target = document.getElementById(id);
    target.scrollIntoView({ behavior: 'smooth' });
  }

  const sections = [
    { name: 'Introduction', id: 'intro' },
    { name: 'Planning', id: 'planning' },
    { name: 'Result', id: 'result' },
    { name: 'Reflection', id: 'reflection' },
  ];

  return (
    <animated.div style={fade} className='flex grow'>
      <LeftBar
        color={props.color}
        handleScroll={handleScroll}
        sections={sections}
        github='https://github.com/milesmaroney/mChat'
        role='Solo'
        time='4 Hours'
        date='January 2022'
      />
      <div className='h-screen p-16 pb-32 overflow-y-scroll scrollBar text-lg'>
        <div
          className='flex md:hidden pb-4 font-bold gap-4'
          style={{ color: props.color }}
        >
          <a
            href='https://github.com/milesmaroney/mChat'
            target='_blank'
            rel='noreferrer'
          >
            GitHub Repo
          </a>
          {/* //
          <a
            href='https://github.com/BlueOceanHRLAX48/AdjacentDoor'
            target='_blank'
            rel='noreferrer'
          >
            Demo
          </a> */}
        </div>
        <div className='text-4xl font-semibold'>mChat</div>
        <div
          className='w-full h-px -mt-1 -z-10'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <img src={mChat} alt='MainDemo' className='rounded mt-4' />
        <div id='intro' className='mt-8 text-4xl font-semibold'>
          Introduction
        </div>
        <p className='pt-4'>
          mChat is a live chat experience similar to Twitch. The idea to build
          mChat originally stemmed from a desire to learn more about websockets
          as well as test how I could functionally implement a color picker into
          a build just because I wanted to. (PS: Click the arrow in the header
          of this site if you haven't yet!)
        </p>
        <p className='pt-4'>
          This was a pretty simple build I threw together in a few hours on a
          Sunday afternoon so this will be a short page. The only thing I'm
          particularly proud of in this project is the auto scroll with pausing
          when you scroll far enough from the bottom. As with the rest of my
          builds, user authentication uses salted and hashed passwords and all
          data is persisted in the database including a user's chosen name color
          and other preferences.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='planning' className='text-4xl font-semibold'>
          Planning
        </div>
        <p className='py-4'>
          Honestly, not much planning went into this. I looked up the
          documentation for socket.io and once I figured out how to set that up,
          I built a simple backend using MongoDB and Express to send and receive
          the user preference updates and login. The front end stayed pretty
          slim as well as I kept it to a single chat. Implementing the inline
          text replacement with emotes was an interesting challenge too, one
          that I'd like to look into more at some point if I have time.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='result' className='text-4xl font-semibold'>
          Result
        </div>
        <video src={mChatVideo} loop autoPlay playsInline className='my-4 rounded-lg' />
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='reflection' className='text-4xl font-semibold'>
          Reflection
        </div>
        <p className='py-4'>
          As simple as this build is, I really enjoyed building it. It was my
          first attempt at using a websocket and what I thought was going to be
          a pretty technically challenging thing turned out to be trivial.
          Eventually if I ever revisit it, I'd like to look into creating
          multiple chats happening simultaneously to really get the hang of
          sockets a bit more.
        </p>
        <p>
          The thing I was happiest about in this build was definitely the auto
          scroll pausing. In one of the mock front-end interviews I watched of
          Dan Abrahamov, the interviewer (ex-Twitch) mentioned that the auto
          scroll pause feature was something that troubled their engineers a bit
          so I was pleased when I came up with a solution relatively quickly
          that performed as expected.
        </p>
      </div>
    </animated.div>
  );
}

export default MChat;
