import React from 'react';
import { useSpring, animated } from 'react-spring';
import MilesFeb from './assets/MilesFeb2022.webp';

function About(props) {
  const [schoolHover, setSchoolHover] = React.useState(false);
  const [linkedinHover, setLinkedinHover] = React.useState(false);

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  return (
    <animated.div
      className='flex flex-col sm:flex-row w-3/4 pb-16 sm:pb-0 grow mx-auto items-center overflow-y-scroll hideScrollbar sm:overflow-clip sm:gap-8'
      style={fade}
    >
      <img src={MilesFeb} alt='Miles' className='md:w-1/3 h-1/2 my-8 sm:my-0 object-cover rounded-lg' />
      <div>
        <div className='text-4xl font-bold flex items-center' style={{ color: props.color }}>
          Hey, I'm Miles
          <div className='mx-2 sm:mx-8 grow h-px animate-pulse' style={{ backgroundColor: props.color }} />
        </div>
        <div className='pt-4'>
          From the time I was 4 years old I had my sights set on a career in motorsports as a professional racecar
          driver. After 2+ decades of championships and successes in racing, I've decided to pursue another passion of
          mine, software development. While my fascination with cars began at a young age, so did my love for
          technology. Growing up I had always been creative and enjoyed bringing concepts to life in the form of
          photography, video production, and graphic design. Software engineering is the perfect fusion of my love for
          creativity and technology to bring complex solutions from concept to reality.
        </div>
        <div className='pt-4'>
          To hit the ground running, I enrolled in{' '}
          <a
            href='https://www.hackreactor.com/coding-bootcamp'
            target='_blank'
            rel='noreferrer'
            className='font-semibold transition-all duration-300'
            style={{ color: schoolHover && props.color }}
            onMouseEnter={() => setSchoolHover(true)}
            onMouseLeave={() => setSchoolHover(false)}
          >
            HackReactor
          </a>
          , a 13-week course consisting of over 1000 hours of full stack development in an AGILE environment.
        </div>
        <div className='pt-4'>
          In 6 months over the Winter of 2021 and early 2022, I went from never having written a line of code to kicking
          things off as a FAANG Software Engineer at Amazon.
        </div>
        <div className='pt-4'>
          For the latest updates on my career as a software engineer, visit my{' '}
          <a
            href='https://www.linkedin.com/in/milesmaroney/'
            target='_blank'
            rel='noreferrer'
            className='font-semibold transition-all duration-300'
            style={{ color: linkedinHover && props.color }}
            onMouseEnter={() => setLinkedinHover(true)}
            onMouseLeave={() => setLinkedinHover(false)}
          >
            LinkedIn
          </a>
          .
        </div>
      </div>
    </animated.div>
  );
}

export default About;
