import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import AboutMe from './About';
import Engineer from './Engineer';
import Racer from './Racer';
import Contact from './Contact';
import AdjacentDoor from './AdjacentDoor';
import SystemScale from './SystemScale';
import Mapper from './Mapper';
import WorkoutKeeper from './WorkoutKeeper';
import MigoShop from './MigoShop';
import MChat from './MChat';
import ReactSpotify from './ReactSpotify';

export default function App(props) {
  const [color, setColor] = React.useState(
    () => JSON.parse(localStorage.getItem('mmColor')) || 'rgb(0, 240, 255)'
  );

  React.useEffect(() => {
    localStorage.setItem('mmColor', JSON.stringify(color));
  }, [color]);

  return (
    <div className='w-screen h-screen flex flex-col overflow-y-clip'>
      <Header color={color} setColor={setColor} />
      <Routes>
        <Route path='/' element={<Home color={color} />} />
        <Route path='/about' element={<AboutMe color={color} />} />
        <Route path='/engineer' element={<Engineer color={color} />} />
        <Route path='/racer' element={<Racer color={color} />} />
        <Route path='/contact' element={<Contact color={color} />} />
        <Route path='/AdjacentDoor' element={<AdjacentDoor color={color} />} />
        <Route path='/systemScale' element={<SystemScale color={color} />} />
        <Route path='/mapper' element={<Mapper color={color} />} />
        <Route
          path='/workoutKeeper'
          element={<WorkoutKeeper color={color} />}
        />
        <Route path='/migoShop' element={<MigoShop color={color} />} />
        <Route path='/mChat' element={<MChat color={color} />} />
        <Route path='/reactSpotify' element={<ReactSpotify color={color} />} />
      </Routes>
    </div>
  );
}
