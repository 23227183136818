import React from 'react';
import { animated, useSpring } from 'react-spring';
import workoutKeeper from './assets/workoutKeeper.webp';
import workoutKeeperDetail from './assets/workoutKeeperDetail.webp';
import workoutKeeperEdit from './assets/workoutKeeperEdit.webp';
import workoutKeeperMobile from './assets/workoutKeeperMobile.webp';
import workoutKeeperMobileDetail from './assets/workoutKeeperMobileDetail.webp';
import workoutKeeperMobileSidebar from './assets/workoutKeeperMobileSidebar.webp';
import AdjacentDoorComments from './assets/AdjacentDoorComments.webp';
import LeftBar from './LeftBar';

function WorkoutKeeper(props) {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  function handleScroll(id) {
    const target = document.getElementById(id);
    target.scrollIntoView({ behavior: 'smooth' });
  }

  const sections = [
    { name: 'Introduction', id: 'intro' },
    { name: 'Planning', id: 'planning' },
    { name: 'Result', id: 'result' },
    { name: 'Reflection', id: 'reflection' },
  ];

  return (
    <animated.div style={fade} className='flex grow'>
      <LeftBar
        color={props.color}
        handleScroll={handleScroll}
        sections={sections}
        github='https://github.com/milesmaroney/workoutKeeper'
        website='https://workoutkeeper.com/'
        role='Solo'
        time='48 Hours'
        date='January 2022'
      />
      <div className='h-screen p-16 pb-32 overflow-y-scroll scrollBar text-lg'>
        <div
          className='flex md:hidden pb-4 font-bold gap-4'
          style={{ color: props.color }}
        >
          <a
            href='https://github.com/milesmaroney/workoutKeeper'
            target='_blank'
            rel='noreferrer'
          >
            GitHub Repo
          </a>
          //
          <a href='https://workoutkeeper.com/' target='_blank' rel='noreferrer'>
            Website
          </a>
        </div>
        <div className='text-4xl font-semibold'>workoutKeeper</div>
        <div
          className='w-full h-px -mt-1 -z-10'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <img src={workoutKeeper} alt='MainDemo' className='rounded mt-4' />
        <div id='intro' className='mt-8 text-4xl font-semibold'>
          Introduction
        </div>
        <p className='pt-4'>
          workoutKeeper was a personal project that I wanted to build to use in
          my day to day life. I've always wanted a tool to easily keep track of
          workouts I've created and figured this was the perfect opportunity to
          practice as well as make something I'll actually use. You can use
          workoutKeeper as a personal workout vault or to share a workout you're
          proud of with friends so they can add it to their own collection.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='planning' className='text-4xl font-semibold'>
          Planning
        </div>
        <p className='py-4'>
          When I started, I had a general UI/UX layout in mind and knew that I
          wanted the app to be well optimized on mobile since you likely aren't
          using a gym app at home as much as you are... well... at the gym. The
          idea of designing for mobile was a bit foreign to me when I started
          this project so I had to do a bit of research on things like being
          able to toggle the left bar away and utilizing screen breakpoints.
        </p>
        <p className='py-4'>
          I knew the app needed to store data, so I decided to utilize a NoSQL
          solution in MongoDB. I had a good idea of how I wanted the schema to
          look and decided to move forward with a user schema that had a
          'exercises' and 'workouts' property that would both be an array of
          exercise and workout schemas containing their own information. Landing
          on a data design that would allow me to have all of the flexibility
          I'd need was probably the most difficult part of the planning,
          especially as I added more features to the backlog while I planned.
          The server routing was fairly trivial as I was pretty confident with
          Mongo by this point so getting the backend setup happened pretty
          quickly once I finalized the schemas.
        </p>
        <p className='py-4'>
          I wanted the app to maintain a darker look so I decided on a color
          palette mixing gray, black, and red. I tried to keep the red to a
          minimum and just utilize it as an accent when necessary and I'm
          pleased with where the UI has landed. I'm a bit unhappy with how busy
          the left bar is with a lot of exercises, but I haven't had time to
          revisit and improve it just yet. Maybe by the time you're reading
          this!
        </p>

        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='result' className='text-4xl font-semibold'>
          Result
        </div>
        <p className='py-4'>
          Utilizing a RESTful API built in Express paired with a MongoDB
          database, workoutKeeper allows you to create, edit, store, and share
          workouts. By default, everything you do on the app is private but can
          be easily shared with anyone in the world. If they're logged in, they
          can also easily add the workout to their own collection with a single
          click. workoutKeeper was built with mobile optimization in mind and
          has many small quality of life improvements to enhance the mobile
          experience.
        </p>
        <p className='py-4'>
          All user data is secured behind a salted and hashed password
          authorization and all data is synced with the database with every
          action. If you make a change on 1 device, they'll be reflected
          immediately on all devices as expected.
        </p>
        <div className='flex flex-col md:flex-row w-full pt-4 gap-4 md:justify-center'>
          <img src={workoutKeeperMobile} alt='mobile' className='rounded-lg' />
          <img
            src={workoutKeeperMobileDetail}
            alt='mobile detail'
            className='rounded-lg'
          />
          <img
            src={workoutKeeperMobileSidebar}
            alt='mobile sidebar'
            className='rounded-lg'
          />
        </div>
        <img
          src={workoutKeeperDetail}
          alt='detail'
          className='rounded-lg my-4'
        />
        <img src={workoutKeeperEdit} alt='edit' className='rounded-lg my-4' />
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='reflection' className='text-4xl font-semibold'>
          Reflection
        </div>
        <p className='py-4'>
          workoutKeeper was my first real attempt at something that I wanted to
          use every day. As a result, it forced me to utilize some new
          technologies that I hadn't worked with yet like React Router to be
          able to have things like workout sharing. While fairly straight
          forward to implement, using React Router introduced a number of
          additional headaches that I hadn't encountered before. The most
          annoying of them all was the inability to directly link someone to a
          path, which would end up resulting in a 404 because technically you
          have to visit index.html to then be routed to the correct element via
          React Router. I ended up solving this at the server level by sending
          the index.html file whenever someone would request any relevant
          endpoint.
        </p>
        <p>
          For the timeframe that I built workoutKeeper in, I'm extremely happy
          with the product and it's something I continue to use every day with
          my workouts. I still have additional features I want to implement
          eventually (random workout generation, refactoring the exercise bar,
          and a few others) but it's currently a product I'm happy to invite
          others to use. If you have any feature suggestions I'm more than happy
          to hear them as I'd love to continue improving workoutKeeper!
        </p>
      </div>
    </animated.div>
  );
}

export default WorkoutKeeper;
