import React from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';
import LinkItem from './LinkItem';

function LeftBar(props) {
  const [backHover, setBackHover] = React.useState(false);

  const navElements = props.sections?.map((section, i) => (
    <LinkItem
      key={i}
      handleScroll={props.handleScroll}
      section={section}
      color={props.color}
    />
  ));

  return (
    <div
      className='w-1/5 bg-neutral-800 p-10 text-neutral-400 min-w-[270px] hidden md:flex md:flex-col'
      style={{ borderColor: props.color }}
    >
      <Link
        to='/engineer'
        className='flex items-center font-semibold text-lg w-fit transition-all duration-300 mb-16'
        style={{ color: backHover && props.color }}
        onMouseEnter={() => setBackHover(true)}
        onMouseLeave={() => setBackHover(false)}
      >
        <MdChevronLeft size='30px' /> BACK
      </Link>
      <a
        href={props.github}
        target='_blank'
        rel='noreferrer'
        className='font-semibold w-fit'
        style={{ color: props.color }}
      >
        GitHub Repo
      </a>
      {props.website && (
        <a
          href={props.website}
          target='_blank'
          rel='noreferrer'
          className='font-semibold w-fit mt-2'
          style={{ color: props.color }}
        >
          Website
        </a>
      )}
      <section className='text-white font-semibold pt-8'>
        <div className='w-full flex'>
          <div className='w-[50px] font-normal'>Date:</div>
          <div>{props.date || 'February 2022'}</div>
        </div>
        <div className='w-full flex'>
          <div className='w-[50px] font-normal'>Time:</div>
          <div>{props.time || '1 Week'}</div>
        </div>
        <div className='w-full flex'>
          <div className='w-[50px] font-normal'>Role:</div>
          <div>{props.role || 'Front End'}</div>
        </div>
      </section>
      <ul className='pt-8'>{navElements}</ul>
    </div>
  );
}

export default LeftBar;
