import React from 'react';

function LinkItem(props) {
  const [hover, setHover] = React.useState(false);
  return (
    <li
      onClick={() => props.handleScroll(props.section.id)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ color: hover && props.color }}
      className='cursor-pointer mt-2 transition-all duration-300'
    >
      {props.section.name}
    </li>
  );
}

export default LinkItem;
