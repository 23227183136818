import React from 'react';
import { animated, useSpring } from 'react-spring';
import AdjacentDoorHome from './assets/AdjacentDoorHome.webp';
import AdjacentDoorWhiteboard from './assets/AdjacentDoorWhiteboard.webp';
import AdjacentDoorFigma from './assets/AdjacentDoorFigma.webp';
import AdjacentDoorGroup from './assets/AdjacentDoorGroup.webp';
import AdjacentDoorGroupAdmin from './assets/AdjacentDoorGroupAdmin.webp';
import AdjacentDoorMyProfile from './assets/AdjacentDoorMyProfile.webp';
import AdjacentDoorComments from './assets/AdjacentDoorComments.webp';
import LeftBar from './LeftBar';

function AdjancentDoor(props) {
  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  function handleScroll(id) {
    const target = document.getElementById(id);
    target.scrollIntoView({ behavior: 'smooth' });
  }

  const sections = [
    { name: 'Introduction', id: 'intro' },
    { name: 'Planning', id: 'planning' },
    { name: 'Result', id: 'result' },
    { name: 'Reflection', id: 'reflection' },
  ];

  return (
    <animated.div style={fade} className='flex grow'>
      <LeftBar
        color={props.color}
        handleScroll={handleScroll}
        sections={sections}
        github='https://github.com/BlueOceanHRLAX48/AdjacentDoor'
        role='Product Manager'
        time='1 Week'
        date='February 2022'
      />
      <div className='h-screen p-16 pb-32 overflow-y-scroll scrollBar text-lg'>
        <div
          className='flex md:hidden pb-4 font-bold gap-4'
          style={{ color: props.color }}
        >
          <a
            href='https://github.com/BlueOceanHRLAX48/AdjacentDoor'
            target='_blank'
            rel='noreferrer'
          >
            GitHub Repo
          </a>
          {/* //
          <a
            href='https://github.com/BlueOceanHRLAX48/AdjacentDoor'
            target='_blank'
            rel='noreferrer'
          >
            Demo
          </a> */}
        </div>
        <div className='text-4xl font-semibold'>AdjacentDoor</div>
        <div
          className='w-full h-px -mt-1 -z-10'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <img src={AdjacentDoorHome} alt='MainDemo' className='rounded mt-4' />
        <div id='intro' className='mt-8 text-4xl font-semibold'>
          Introduction
        </div>
        <p className='pt-4'>
          AdjacentDoor is an application that allows neighbors and community
          members to socialize, buy and sell items, alert others of nearby
          threats, and more. In addition to your default neighborhood group
          based on location, users can create and fine tune additional groups
          for more specific communities.
        </p>
        <p className='pt-4'>
          In this project, I was elected as the Product Manager in addition to
          my role as the lead developer by the other 6 engineers on the team.
          This was my first experience as a Product Manager and while it was a
          significant increase in the workload, I was thankful for the
          experience and enjoyed being able to direct the project.
        </p>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='planning' className='text-4xl font-semibold'>
          Planning
        </div>
        <p className='py-4'>
          AdjacentDoor was a product built for a client based on a verbal list
          of requests. After meeting with the client, it was clear that their
          expectations were misaligned with the requested 1 week timeframe.
          After a brief meeting with my team, we decided on a scope we were
          comfortable delivering in the time frame and met with the client once
          again to finalize a user agreement. The client was content with the
          features we focused on and we began working to lay out as many of the
          details as possible before starting.
        </p>
        <div className='flex flex-col md:flex-row w-full items-center'>
          <a
            href='https://miro.com/app/board/uXjVORwwS14=/?invite_link_id=157877812414'
            className='md:w-1/2'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={AdjacentDoorWhiteboard}
              alt='whiteboarding'
              className='rounded-lg'
            />
          </a>
          <p className='md:w-1/2 pl-4'>
            We started off whiteboarding out a rough plan for our component
            structure. This allowed us to think through the feature set a bit
            more in depth and find any potential component we could share across
            features. I know it's a bit hard to read at this resolution, so if
            you'd like to visit the board directly click{' '}
            <a href='https://miro.com/app/board/uXjVORwwS14=/?invite_link_id=157877812414'>
              <b>here.</b>
            </a>
          </p>
        </div>
        <div className='flex flex-col md:flex-row w-full items-center pt-8 md:pt-0'>
          <p className='md:w-1/2 pr-4'>
            While the whiteboarding helped us flush out and prevent potential
            issues with the structure, we also drafted a loose guide on how we
            wanted the flow of the application to go from a design standpoint.
            For a direct link to the Figma file, click{' '}
            <a href='https://www.figma.com/file/33oIqtCLGOPI4HZqhCinAg/Adjacent-Door?node-id=0%3A1'>
              <b>here.</b>
            </a>
          </p>
          <a
            href='https://www.figma.com/file/33oIqtCLGOPI4HZqhCinAg/Adjacent-Door?node-id=0%3A1'
            className='md:w-1/2'
            target='_blank'
            rel='noreferrer'
          >
            <img src={AdjacentDoorFigma} alt='figma' className='rounded-lg' />
          </a>
        </div>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='result' className='text-4xl font-semibold'>
          Result
        </div>
        <p className='py-4'>
          Utilizing a RESTful API built in Express paired with a PostgreSQL
          database, my team and I transformed AdjacentDoor from a client pitch
          into a fully operational full-stack application in a single week. The
          intense time constraint meant that all of our planning was critical to
          our eventual success and I'm pleased to say the client was overjoyed
          with the end result.
        </p>
        <img src={AdjacentDoorGroup} alt='groupdetail' className='rounded-lg' />
        <div className='flex w-full pt-4 gap-4'>
          <div>
            <img
              src={AdjacentDoorGroupAdmin}
              alt='groupadmin'
              className='rounded-lg'
            />
            <img
              src={AdjacentDoorComments}
              alt='comments'
              className='w-full rounded-lg mt-5'
            />
          </div>
          <img
            src={AdjacentDoorMyProfile}
            alt='myprofile'
            className='rounded-lg w-1/2 object-cover'
          />
        </div>
        <div
          className='w-full h-px my-20'
          style={{
            background: `linear-gradient(90deg, ${props.color}, transparent)`,
          }}
        />
        <div id='reflection' className='text-4xl font-semibold'>
          Reflection
        </div>
        <p className='py-4'>
          For me, AdjacentDoor was an incredible learning experience... one that
          I feel many engineers don't experience well into their time in
          industry. Wearing the Product Manager hat allowed me to get direct
          insight on the varying levels of time required for different tasks
          among a team and certainly made me a better collaborative team member.
          If I had a chance to do it all over again, there were a few painpoints
          that I'd like to have remedied that we didn't run into until it was a
          bit late like a lack of global access to the User state and
          currentLocation state; both of which we used in a number of nested
          components where it was easy to lose them.
        </p>
        <p>
          In terms of actual time management, I feel the team budgeted very well
          and was able to stay on track with all of my requests for deadlines.
          Our large portion of time spent in the planning phase early on paid
          off well when it was time to connect all of the individual components
          together. I had a vision for how I wanted things like the category
          filters and search bar to be able to interact across components and
          remain universal whenever applicable and making sure the team adhered
          to that plan made the backend of the project seamless. The biggest
          thing I learned through this project was that <i>everything</i> takes
          more time when you're dealing with a team, especially a larger one,
          and you need to be able to trust your teammates. I figured by the end
          of this project all of the other engineers would be tired of hearing
          my voice but instead they nominated me to speak at our graduation next
          week... Go figure!
        </p>
      </div>
    </animated.div>
  );
}

export default AdjancentDoor;
